import React from "react";
// import { graphql } from "gatsby";

const ListBlock = ({ block, ...props }) => {
	return block.imageList ? (
		<div className="bg-purple w-full my-12">
			{block.imageList.map((listItem, i) => (
				<div key={i}>
					<img
						src={
							listItem.image[0].url +
							"?sat=-100&w=608&h=342&fit=crop&crop=faces&facepad=5"
						}
						alt=""
						className="img-fluid w-full md:w-1/4"
					/>
					<h4 className="text-3xl">{listItem.heading}</h4>
					{listItem.text ? (
						<div
							className="reading-smaller p-6"
							dangerouslySetInnerHTML={{
								__html: listItem.text.content
							}}
						/>
					) : null}
				</div>
			))}
		</div>
	) : null;
};

export default ListBlock;
