import React from "react";
// import { graphql } from "gatsby";

const BlockquoteBlock = ({ block, ...props }) => {
	return block.blockquote === null ? (
		<p>There is no body text</p>
	) : (
		<blockquote className="blockquote border-t-4 border-b-4 border-black py-8 mb-12 text-right">
			<p className="text-5xl uppercase font-dincond font-black leading-none my-0 ignoreReading">
				{block.blockquote}
			</p>
			{block.sourceName ? (
				<p className="uppercase tracking-wider text-xs mt-4 ignoreReading">
					&mdash; {block.sourceName}
				</p>
			) : null}
		</blockquote>
	);
};

export default BlockquoteBlock;

// THIS WORKS!
// {block.sourceLink && <h2>{block.sourceLink}</h2>}

// THIS WORKS!
// {block.sourceLink ? block.sourceLink : null}

// {block.sourceName.length > 0 && (
//     <p className="uppercase tracking-wider text-xs mt-4 ignoreReading">
//         &mdash;
//         {block.sourceName}
//     </p>
// )}

// {block.sourceLink ? block.sourceLink : null}
