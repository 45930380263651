import React from "react";
// import { graphql } from "gatsby";

const Image = ({ block, ...props }) => {
	return block.imageCollection ? (
		<div className="bg-purple w-full">
			{block.imageCollection.map((image, i) => (
				<img
					src={
						image.url +
						"?blur=10&sat=-100&w=608&h=342&fit=crop&crop=faces&facepad=5"
					}
					alt=""
					className="img-fluid w-full"
				/>
			))}
		</div>
	) : (
		<h4>frankensalts</h4>
	);
};

export default Image;
