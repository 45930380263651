import React from "react";
// import { graphql } from "gatsby";

const Image = ({ block, ...props }) => {
	return block.image[0] ? (
		<div className="bg-purple w-full">
			<img
				src={block.image[0].url + "?auto=format,compress&q=57&w=1400&fit=clip"}
				alt=""
				className="img-fluid w-full"
			/>
		</div>
	) : null;
};

export default Image;

// ?sat=100&w=608&h=342&fit=crop&crop=faces&facepad=5
