import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import { relatedBlog } from "~/queries"; // eslint-disable-line
import { relatedListings } from "~/queries/relatedListings"; // eslint-disable-line
import { relatedOffices } from "~/queries/relatedListings"; // eslint-disable-line
import Layout from "../components/layout";
import ContentBuilder from "../components/ContentBuilder";
import MiniDrawer from "../components/Drawer/MiniDrawer";
import PageWrapper from "../components/Structure/PageWrapper";
import SEO from "../components/seo";

class BlogPost extends Component {
	render() {
		const {
			data: {
				recraft: { post },
				recraft: { relatedItems }
			}
		} = this.props;

		return (
			<Layout location={this.props.location}>
				<SEO
					title={post.title}
					keywords={[`dumbo`, `places`, `dumbo businesses`]}
				/>
				<PageWrapper
					imageObject={
						!post.featuredImage || !post.featuredImage.length
							? null
							: post.featuredImage[0]
					}
					title={post.title}
					body={post.body ? post.body.content : null}
					bonusContent={
						<div className="my-8">
							<ContentBuilder data={post} />
							<Link to="/blogging">Back</Link>

							{relatedItems.relatedSelector.length ? (
								<h2 className="font-dincond uppercase">Mentioned here</h2>
							) : null}
							{relatedItems.relatedSelector.map((entry, i) => (
								<MiniDrawer key={i} entry={entry} buttonStyle="badge" />
							))}
						</div>
					}
				/>
			</Layout>
		);
	}
}

export default BlogPost;

export const pageQuery = graphql`
	query BlogPostByUri($uri: String!) {
		recraft {
			post: entry(uri: $uri) {
				...relatedBlog
			}
			relatedItems: entry(uri: $uri) {
				... on Recraft_News {
					relatedSelector {
						...relatedListings
						...relatedOffices
					}
				}
			}
		}
	}
`;
