/* global tw */
import React from "react";
import styled from "styled-components";

const HeadingBlock = styled.div`
	${tw`block bg-yellow my-8`};
`;

const MainHeading = styled.h2`
	${tw`text-4xl uppercase text-black`};
`;

const SubHeading = styled.h3`
	${tw`text-2xl uppercase text-black`};
`;

const Heading = ({ block, ...props }) => {
	return (
		<HeadingBlock>
			<MainHeading>{block.heading}</MainHeading>
			<SubHeading>{block.subhead}</SubHeading>
		</HeadingBlock>
	);
};

export default Heading;

// <p>
// <em>heading block</em>
// </p>
