/* global tw */
import React from "react";
import styled from "styled-components";

const Body = styled.div`
	${tw`w-full md:w-3/4 mx-auto text-lg md:text-reading font-normal leading-normal md:leading-reading`};

	p {
		${tw`mb-4`};
		&:last-child,
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	p.q {
		${tw`text-2xl md:text-3xl font-medium`};
		margin-bottom: 10px;
		margin-top: 2rem;
	}
`;

const Text = ({ block, ...props }) => {
	return block.copy ? (
		<Body dangerouslySetInnerHTML={{ __html: block.copy.content }} />
	) : null;
};

export default Text;

// font-size: 26px;
// font-family: "DINWeb-Medium","Helvetica Neue",Helvetica,Arial,sans-serif;
// line-height: 1.33;
// margin-bottom: 10px;
// margin-top: 2rem;
