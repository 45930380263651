import React from "react";
// import { graphql } from "gatsby";

const ImageFloat = ({ block, ...props }) => {
	return block.image[0] ? (
		<div className="bg-purple w-full">
			<img
				src={
					block.image[0].url +
					"?sat=-100&w=608&h=342&fit=crop&crop=faces&facepad=5"
				}
				alt=""
				className="img-fluid w-full"
			/>
			<h4>{block.floatSide}</h4>
			<h5>{block.customCaption}</h5>
		</div>
	) : null;
};

export default ImageFloat;
