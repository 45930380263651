import React from "react";
import HeadingBlock from "./Blocks/Heading";
import ImageBlock from "./Blocks/Image";
import ImageFloat from "./Blocks/ImageFloat";
import ImageCollectionBlock from "./Blocks/ImageCollection";
import TextBlock from "./Blocks/Text";
import ListBlock from "./Blocks/ListBlock";
import BlockquoteBlock from "./Blocks/BlockquoteBlock";

const ContentBuilder = ({ data }) => (
	<React.Fragment>
		{data.contentBuilder.map((block, index) => {
			switch (block.__typename) {
				case "Recraft_ContentBuilderHeading":
					return (
						<HeadingBlock block={block} key={`${block.__typename}-${index}`} />
					);
				case "Recraft_ContentBuilderCopy":
					return (
						<TextBlock block={block} key={`${block.__typename}-${index}`} />
					);
				case "Recraft_ContentBuilderBlockquote":
					return (
						<BlockquoteBlock
							block={block}
							key={`${block.__typename}-${index}`}
						/>
					);
				case "Recraft_ContentBuilderImageBlock":
					return (
						<ImageBlock
							block={block}
							key={`${block.__typename}-${index}`}
							width="400"
						/>
					);
				case "Recraft_ContentBuilderImageFloat":
					return (
						<ImageFloat
							block={block}
							key={`${block.__typename}-${index}`}
							width="400"
						/>
					);
				case "Recraft_ContentBuilderImageCollection":
					return (
						<ImageCollectionBlock
							block={block}
							key={`${block.__typename}-${index}`}
							width="400"
						/>
					);
				case "Recraft_ContentBuilderListBlocking":
					return (
						<ListBlock block={block} key={`${block.__typename}-${index}`} />
					);
				default:
					return null;
			}
		})}
	</React.Fragment>
);
export default ContentBuilder;

// <ImageBlock
// 							block={block}
// 							key={`${block.__typename}-${index}`}
// 							width="400"
// 						/>

// <img
// src={
//     block.image[0].url +
//     "?sat=-100&w=608&h=342&fit=crop&crop=faces&facepad=5"
// }
// alt=""
// className="img-fluid w-full"
// />
